import React from "react";
import { mapping } from "@aim-mf/styleguide";
import { ExistingControl } from "./riskRegisterComponents/existingControl";
import { DriversAndConsequences } from "./riskRegisterComponents/driversAndConsequences";
import { KRIIndicator } from "./riskRegisterComponents/keyRiskIndicator";
import { ActionPlans } from "./riskRegisterComponents/actionPlans";
import { RiskSummary } from "./riskSummary";

const RiskSummaryStyle = {
  width: "100%",
  height: "fit-content",
  backgroundColor: mapping["Color_BG_05_solid"],
};

const blockPaddingStyle = {
  paddingLeft: "5rem",
  paddingTop: "3rem",
};

class RiskEditManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskList: this.props.riskList,
      createNewRisk: false,
    };
  }

  render() {
    return (
      <div style={RiskSummaryStyle}>
        <DriversAndConsequences
          title={"Drivers & Consequences"}
          style={blockPaddingStyle}
          drivers={this.props.riskDetail.drivers}
          consequences={this.props.riskDetail.consequences}
          lastEdit={this.props.lastEdit}
          handleRiskDriverChange={this.props.handleRiskDriverChange}
          handleRiskConseqChange={this.props.handleRiskConseqChange}
          rights={this.props.rights}
          handleCreateTask={this.props.handleCreateTask}
        />
        <ExistingControl
          title={"Existing Controls"}
          style={blockPaddingStyle}
          lastEdit={this.props.lastEdit}
          handleRiskControlChange={this.props.handleRiskControlChange}
          controls={this.props.riskDetail.controls}
          handleAddUser={this.props.handleAddUser}
          rights={this.props.rights}
          handleSearchControls={this.props.handleSearchControls}
          searching={this.props.searching}
          controlsList={this.props.controlsList}
          controlSearchIndex={this.props.controlSearchIndex}
          handleControlDetailRequest={this.props.handleControlDetailRequest}
          handleCreateTask={this.props.handleCreateTask}
        />
        <KRIIndicator
          title={"Key Risk Indicator"}
          style={blockPaddingStyle}
          lastEdit={this.props.lastEdit}
          handleKRIChange={this.props.handleKRIChange}
          KRIs={this.props.riskDetail.KRIs}
          handleAddUser={this.props.handleAddUser}
          rights={this.props.rights}
          handleCreateTask={this.props.handleCreateTask}
          handleKRIEntry={this.props.handleKRIEntry}
        />
        <ActionPlans
          title={"Action Plans"}
          style={blockPaddingStyle}
          lastEdit={this.props.lastEdit}
          actionPlans={this.props.riskDetail.actionplans}
          handleActionPlanChange={this.props.handleActionPlanChange}
          handleAddUser={this.props.handleAddUser}
          rights={this.props.rights}
          handleCreateTask={this.props.handleCreateTask}
        />
        <div style={bottomPaddingStyle} />
      </div>
    );
  }
}
const bottomPaddingStyle = {
  height: "5rem",
};
export { RiskEditManagement };
