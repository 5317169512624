import React from "react";
import {
  IconButtonSolid,
  IconSVG,
  mapping,
  NonValidationInput,
  RiskEditCardHeader,
  AutoSizeTextArea,
} from "@aim-mf/styleguide";
import { RiskEditCardStyle } from "../risk-edit-common-style";
const DriversAndConsequencesStyle = {
  paddingLeft: "5rem",
  paddingTop: "4rem",
};

class DriversAndConsequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div style={DriversAndConsequencesStyle}>
        <div style={RiskEditCardStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />
          <div style={{ margin: "1.25rem" }}>
            {this.props.rights.edit === 1 ? (
              <ContentBox
                title={"Risk Drivers"}
                content={this.props.drivers}
                onAddNew={this.handleAddNewDriver}
                onNewItemChange={this.handleDriverNewItemChange}
                handleDelete={this.handleDeleteDriver}
              />
            ) : (
              <ContentBoxView
                title={"Risk Drivers"}
                content={this.props.drivers}
              />
            )}
          </div>
          <div style={{ margin: "1.43rem", marginTop: 0 }}>
            {this.props.rights.edit === 1 ? (
              <ContentBox
                title={"Risk Consequences"}
                content={this.props.consequences}
                onAddNew={this.handleAddNewConseq}
                onNewItemChange={this.handleConseqNewItemChange}
                handleDelete={this.handleDeleteConseq}
              />
            ) : (
              <ContentBoxView
                title={"Risk Consequences"}
                content={this.props.consequences}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  handleAddNewDriver = () => {
    let array = this.props.drivers;
    array.push("");
    this.props.handleRiskDriverChange(array);
  };
  handleAddNewConseq = () => {
    let array = this.props.consequences;
    array.push("");
    this.props.handleRiskConseqChange(array);
  };
  handleDriverNewItemChange = (value, index) => {
    let array = this.props.drivers;
    array[index] = value;
    this.props.handleRiskDriverChange(array);
  };
  handleConseqNewItemChange = (value, index) => {
    let array = this.props.consequences;
    array[index] = value;
    this.props.handleRiskConseqChange(array);
  };
  handleDeleteDriver = (index) => {
    let array = this.props.drivers;
    array.splice(index, 1);
    this.props.handleRiskDriverChange(array);
  };
  handleDeleteConseq = (index) => {
    let array = this.props.consequences;
    array.splice(index, 1);
    this.props.handleRiskConseqChange(array);
  };
}

const ContentBox = (props) => {
  return (
    <div style={BoxStyle}>
      <div style={{ padding: "1rem" }}>
        <div style={RiskEditCardStyle.sectionTitleStyle}>{props.title}</div>
      </div>
      <div style={{ paddingLeft: "1rem" }}>
        {props.content.map((content, index) => {
          return (
            <div
              key={index}
              style={{ padding: "0.4rem", display: "inline-flex" }}
            >
              <TextInput
                id={index}
                value={content}
                onCustomChange={props.onNewItemChange}
              />
              <div style={{ width: "10px" }} />
              <IconSVG
                name={"delete"}
                color={mapping["Color_Basic_Mute_2"]}
                onClick={() => {
                  props.handleDelete(index);
                }}
              />
            </div>
          );
        })}
        <div style={{ padding: "0.4rem", paddingBottom: "2rem" }}>
          <IconButtonSolid
            height={"2rem"}
            width={"5rem"}
            icon={<IconSVG name={"newRisk"} size={"0.8"} color={"#ffffff"} />}
            name={"Add"}
            iconPos={"Right"}
            clickEvent={props.onAddNew}
          />
        </div>
      </div>
    </div>
  );
};

const ContentBoxView = (props) => {
  return (
    <div style={BoxStyle}>
      <div style={{ padding: "1rem" }}>
        <div style={RiskEditCardStyle.sectionTitleStyle}>{props.title}</div>
      </div>
      <div style={{ paddingLeft: "1rem" }}>
        {props.content.map((content, index) => {
          return (
            <div key={index} style={{ padding: "0.4rem" }}>
              <TextContent content={content} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.id);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={InputFieldStyle}
      value={props.value}
      CustomizedOnChange={onChange}
    />
  );
};
const TextContent = (props) => {
  return (
    <div style={TextWrapperStyle}>
      <div style={TextStyle}>
        {" "}
        {props.content === "" ? "\u00A0" : props.content}{" "}
      </div>
    </div>
  );
};
const TextWrapperStyle = {
  padding: "0.25rem",
  height: "fit-content",
  width: "56rem",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "1px solid rgba(151,151,151,0)",
};
const TextStyle = {
  ...mapping["forms/input-default"],
  color: "white",
  padding: "0.25rem",
};
var BoxStyle = {
  marginTop: "1rem",
  width: "65.875rem",
  height: "fit-content",
  background: mapping["Color_BG_02_solid"],
  boxShadow: "2px 2px 10px rgb(41,50,61)", // X axis, Y axis ,blur, colour
};

const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "61rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};

export { DriversAndConsequences };
