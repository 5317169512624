import React from "react";
import { RiskVersion } from "./riskRegisterComponents/riskVersion";
import {
  IconSVG,
  mapping,
  Pill,
  SmallAvatar,
  ButtonSolid,
  Risk_Matrix_Component_Block,
  Tab_BookMark,
  DropDownListButton,
  NameCard,
  FormatDate,
  AutoSizeTextArea,
} from "@aim-mf/styleguide";

const VersionButton = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  outline: "none",
};

const VersionButtonHover = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  border: "2px solid #00A2FF",
  outline: "none",
};
const SendButton = {
  color: "white",
  "background-color": "#6C757D",
  "border-radius": "5px",
  padding: "2px 8px 2px 8px",
  "font-size": 10,
};

class RiskBasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskList: this.props.riskList,
      versionButtonHoverID: -1,
    };
  }

  render() {
    const RiskStatus = "Working Draft";
    const RiskStatusBGColor = "#FDD60066";

    //console.log(this.props.riskDetail)
    //console.log(this.props.parentRiskDropDownItem)

    return (
      <div style={RiskBasicInfoStlye}>
        <div style={{ display: "inline-flex" }}>
          <IconSVG
            name={"home"}
            size={"0.8"}
            color={mapping["Color_Basic_Primary"]}
          />
          <div style={parentRiskStyle}>
            {this.props.riskDetail.isParentRisk ? "Parent Risk" : "Sub Risk"}
          </div>
        </div>
        <div style={riskStatusStyle}>
          <Pill color={RiskStatusBGColor} content={RiskStatus} />
        </div>
        <h1 style={riskNameStyle}>{this.props.riskDetail.name}</h1>

        <div style={riskInfoSectionWrapperStyle}>
          <div style={descriptionWrapperStyle}>
            <div style={sectionTitleStyle}>description</div>
            <TextInput
              width={"360px"}
              value={this.props.riskDetail.description}
              onCustomChange={this.handleRiskDescriptionChange}
            />
          </div>

          {/*risk owner section*/}
          <div style={riskOwnerWrapperStyle}>
            <div style={sectionTitleStyle}>RISK OWNER</div>
            {this.props.riskDetail.owner.map((owner) => {
              return (
                <div style={riskOwnerContentStyle}>
                  <NameCard
                    key={owner}
                    initial={owner.display_name
                      .split(" ")
                      .map((a) => {
                        return a[0].toUpperCase();
                      })
                      .join("")}
                    name={owner.display_name}
                    title={owner.title}
                  />
                </div>
              );
            })}
            {this.props.rights.create === 1 && (
              <div style={{ paddingTop: "1.25rem" }}>
                <ButtonSolid
                  name={"Add\u00a0\u00a0+"}
                  fontStyle={mapping["Font_Button_Badge_White"]}
                  height={"18px"}
                  width={"52px"}
                  size={"Small"}
                  clickEvent={this.handleOwnerChange}
                />
              </div>
            )}
          </div>

          {/*risk delegate section*/}
          <div style={riskDelegateWrapperStyle}>
            <div style={sectionTitleStyle}>RISK DELEGATES</div>
            {this.props.riskDetail.delegate.map((owner) => {
              return (
                <div style={riskOwnerContentStyle}>
                  <NameCard
                    key={owner}
                    initial={owner.display_name
                      .split(" ")
                      .map((a) => {
                        return a[0].toUpperCase();
                      })
                      .join("")}
                    name={owner.display_name}
                    title={owner.title}
                  />
                </div>
              );
            })}
            {this.props.rights.delegate === 1 && (
              <div style={{ paddingTop: "1.25rem" }}>
                <ButtonSolid
                  name={"Add\u00a0\u00a0+"}
                  fontStyle={mapping["Font_Button_Badge_White"]}
                  height={"18px"}
                  width={"52px"}
                  size={"Small"}
                  clickEvent={this.handleDelegateChange}
                />
              </div>
            )}
          </div>

          {/*risk rating section*/}
          <div style={riskRatingWrapperStyle}>
            <div style={sectionTitleStyle}>RISK RATING</div>
            <div style={riskRatingContentStyle}>
              <Risk_Matrix_Component_Block
                MatrixSetting={this.state.matrixSetting}
                singleRisk={true}
                riskRating={[
                  {
                    Gross: this.props.riskDetail.gross,
                    Target: this.props.riskDetail.target,
                    Residual: this.props.riskDetail.residual,
                  },
                ]}
                categoryList={["Gross", "Residual", "Target"]}
              />
            </div>
          </div>

          {/*risk version section*/}
          <RiskVersion
            riskDetail={this.props.riskDetail}
            handleUpdateWithNewRiskDetail={
              this.props.handleUpdateWithNewRiskDetail
            }
          />
        </div>
        <div>
          <div style={{ display: "inline-flex" }}>
            {/*risk tier section*/}
            <div style={RiskTierWrapperStyle}>
              <div style={sectionTitleStyle}>Risk Tier</div>
              <div style={riskTierDropDownWrapperStyle}>
                <DropDownListButton
                  value={"Tier " + this.props.riskDetail.tier}
                  data={
                    this.props.riskDetail.isParentRisk
                      ? ["Tier 1", "Tier 2", "Tier 3"]
                      : ["Tier 1", "Tier 2", "Tier 3"].slice(
                          this.props.parentRiskTier
                        )
                  }
                  CustomOnChange={this.props.handleRiskTierChange}
                  DropDownButtonStyle={riskTierDropDownButtonStyle}
                  disabled={!this.props.rights.edit}
                />
              </div>
            </div>
            {/*risk category section*/}
            <div style={RiskCategoryWrapperStyle}>
              <div style={sectionTitleStyle}>Category</div>
              <div style={riskCategoryDropDownWrapperStyle}>
                <DropDownListButton
                  value={this.props.riskDetail.category}
                  data={this.props.CategoryList}
                  CustomOnChange={this.props.handleCategoryChange}
                  DropDownButtonStyle={riskTierDropDownButtonStyle}
                  disabled={
                    !this.props.rights.edit ||
                    !this.props.riskDetail.isParentRisk
                  }
                />
              </div>
            </div>
          </div>
          {!this.props.riskDetail.isParentRisk && (
            <div style={RiskParentWrapperStyle}>
              <div style={sectionTitleStyle}>Parent Risk</div>
              <div style={riskParentDropDownWrapperStyle}>
                <DropDownListButton
                  value={{ name: this.props.riskDetail.parent_risk_name }}
                  data={this.props.parentRiskDropDownItem}
                  dataDisplayKey={"name"}
                  CustomOnChange={this.props.handleParentRiskChange}
                  DropDownButtonStyle={riskTierDropDownButtonStyle}
                  disabled={
                    !this.props.rights.edit ||
                    !this.props.riskDetail.access_right.edit ||
                    this.props.riskDetail.isParentRisk
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div style={{ display: "inline-flex" }}>
          <Tab_BookMark
            OnTabChange={this.props.handleTabChange}
            tabWidth={"9rem"}
            height={"3.5rem"}
            labels={["Risk Summary", "Risk Management"]}
            activeBackgroundColor={mapping["Color_BG_05_solid"]}
          />
        </div>
        <div
          style={{
            marginLeft: "23.5rem",
            verticalAlign: "bottom",
            paddingBottom: "14px",
            display: "inline-flex",
          }}
        >
          {this.props.rights.delegate === 1 && false && (
            <ButtonSolid
              name={"Send to Delegates"}
              color={"Primary"}
              clickEvent={() => {
                this.props.handleConfirmation(
                  "Delegate Risk",
                  "You are about to delegate this risk. A new draft version will be created.",
                  this.props.handleSendToDelegate
                );
              }}
              size={"Small"}
              height={"31px"}
              width={"127px"}
              disabled={this.props.disableSendDelegate}
            />
          )}
          <div style={{ width: "19px" }} />
          {this.props.rights.create === 1 && false && (
            <ButtonSolid
              name={"Send to Owner"}
              color={"Primary"}
              clickEvent={() => {
                this.props.handleConfirmation(
                  "Change Risk Owner",
                  "You are about to change the owner of this risk. A new draft version will be created.",
                  this.props.handleSendToOwner
                );
              }}
              size={"Small"}
              height={"31px"}
              width={"127px"}
              disabled={this.props.disableSendOwner}
            />
          )}
          <div style={{ width: "19px" }} />
          <ButtonSolid
            name={"Save Changes"}
            color={"Primary"}
            clickEvent={() => {
              this.props.handleConfirmation(
                "Save Changes",
                "You are about to save changes made to this risk. A new draft version will be created.",
                this.props.handleSaveAsDraft
              );
            }}
            size={"Small"}
            height={"31px"}
            width={"127px"}
            disabled={
              this.props.disableSaveChange || this.props.rights.edit === 0
            }
          />
          <div style={{ width: "19px" }} />
          {this.props.rights.approve === 1 ? (
            <ButtonSolid
              name={"Approve"}
              color={"Primary"}
              clickEvent={() => {
                this.props.handleConfirmation(
                  "Approve Risk",
                  "You are about to approve this risk. the status of this risk will be active.",
                  this.props.handleApprove
                );
              }}
              size={"Small"}
              height={"31px"}
              width={"127px"}
              disabled={this.props.riskDetail.status === "active"}
            />
          ) : (
            <ButtonSolid
              name={"Submit Changes"}
              color={"Primary"}
              clickEvent={() => {
                this.props.handleConfirmation(
                  "Risk submission",
                  "You are about to submit this risk to the risk owner or risk manager. A new version will be created, and you will not be able to make any changes to this risk.",
                  this.props.handleSubmitChange
                );
              }}
              size={"Small"}
              height={"31px"}
              width={"127px"}
              disabled={this.props.rights.edit === 0}
            />
          )}
        </div>
      </div>
    );
  }
  handleOwnerChange = () => {
    this.props.handleAddUser(
      "risk",
      -1,
      this.props.riskDetail.owner,
      "Assign Risk Owner"
    );
  };
  handleDelegateChange = () => {
    this.props.handleAddUser(
      "delegate",
      -1,
      this.props.riskDetail.delegate,
      "Assign Risk Delegate"
    );
  };
  handleRiskDescriptionChange = (value) => {
    this.props.handleRiskDescriptionChange(value);
  };
}

const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={{ ...InputFieldStyle, width: props.width }}
      value={props.value}
      CustomizedOnChange={onChange}
    />
  );
};

const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "63rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};

const RiskBasicInfoStlye = {
  width: "100%",
  height: "fit-content",
  backgroundColor: "#283244",
  paddingTop: "1rem",
  paddingLeft: "5rem",
};

const parentRiskStyle = {
  paddingTop: "0.2rem",
  ...mapping["symbols/breadcrumbs/active"],
  color: "#6C757D",
};

const riskStatusStyle = {
  paddingTop: "1.25rem",
};

const riskNameStyle = {
  paddingTop: "1rem",
  ...mapping["heading/h3/lightleft"],
};

const riskInfoSectionWrapperStyle = {
  width: "100%",
  height: "15rem",
  display: "inline-flex",
};

const descriptionWrapperStyle = {
  width: "25rem",
  height: "100%",
};
const riskOwnerWrapperStyle = {
  width: "15rem",
  height: "100%",
};
const riskDelegateWrapperStyle = {
  width: "15rem",
  height: "100%",
};
const riskTierDropDownWrapperStyle = {
  width: "100%",
  height: "100%",
};
const riskCategoryDropDownWrapperStyle = {
  width: "100%",
  height: "100%",
};
const riskParentDropDownWrapperStyle = {
  width: "100%",
  height: "100%",
};
const riskRatingWrapperStyle = {
  width: "15rem",
  height: "15rem",
};
const riskVersionWrapperStyle = {
  width: "25rem",
  height: "100%",
  fontSize: 0,
  position: "relative",
};
const sectionTitleStyle = {
  ...mapping["Sub Text Tag/H7-blue-tag"],
  color: "#00BEFF",
  paddingBottom: "1.25rem",
};
const descriptionContentStyle = {
  ...mapping["paragraph/small/lightleft"],
};
const riskOwnerContentStyle = {
  display: "inline-flex",
  paddingBottom: "1.25rem",
};

const riskOwnerRoleStyle = {
  ...mapping["Font_Avatar_Role"],
  paddingTop: "0.2rem",
};

const riskRatingContentStyle = {
  transform: "scale(0.4)",
  transformOrigin: "top left",
  paddingTop: "1rem",
};

const RiskTierWrapperStyle = {
  width: "73px",
  height: "fit-content",
  marginRight: "63px",
  paddingBottom: "3rem",
};

const RiskCategoryWrapperStyle = {
  width: "208px",
  height: "fit-content",
  marginRight: "63px",
  paddingBottom: "3rem",
};

const RiskParentWrapperStyle = {
  width: "208px",
  height: "fit-content",
  marginRight: "63px",
  paddingBottom: "3rem",
};

const riskTierDropDownButtonStyle = {
  width: "100%",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const versionButtonContentStyle = {
  display: "inline-flex",
  width: "fit-content",
  margin: "auto",
  height: "fit-content",
};
const versionButtonLeftIconStyle = {
  backgroundColor: mapping["Color_Basic_Secondary"],
  borderRadius: "50%",
  width: "2.375rem",
  height: "2.375rem",
};
const versionButtonNameStyle = {
  textAlign: "left",
  ...mapping["Font_Version_Name"],
  lineHeight: "17px",
};
const versionButtonDateStyle = {
  ...mapping["Font_Version_Date"],
  width: "50%",
  textAlign: "left",
  display: "inline-flex",
};
const versionButtonStatusStyle = {
  ...mapping["Font_Version_Status"],
  width: "50%",
  textAlign: "left",
  display: "inline-flex",
};
const versionDotLineStyle = {
  top: "4rem",
  marginLeft: "22.5px",
  height: "14rem",
  display: "inline-block",
  borderStyle: "dashed",
  borderLeftWidth: "1px",
  zIndex: "0",
  position: "absolute",
  borderColor: "rgba(181,198,209,0.5)",
};
const versionButtonViewMoreWrapperStyle = {
  marginLeft: "18px",
  fontSize: 0,
  position: "absolute",
  top: "18rem",
};
const versionDotCircleStyle = {
  height: "10px",
  width: "10px",
  backgroundColor: "283244",
  borderRadius: "50%",
  border: "solid",
  borderColor: "#6C7A88",
  display: "inline-block",
};
const versionButtonViewMoreStyle = {
  padding: "0px",
  textAlign: "top",
  marginLeft: 6,
  marginBottom: 0,
  position: "relative",
  top: -3,
  zIndex: 2,
  display: "inline-block",
  textDecoration: "underline",
  outline: "none",
  height: "16px",
  width: "57px",
  color: "#00BEFF",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: 500,
  letterSpacing: 0,
  lineHheight: "16px",
};
export { RiskBasicInfo };
